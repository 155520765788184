import { forwardRef , type ReactNode, type HTMLProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const Error = forwardRef<HTMLDivElement, { className?: string; children: ReactNode } & HTMLProps<HTMLDivElement>>(({ className, children, ...props }, ref) => {
	const baseClass = `flex py-2 px-4 gap-2 items-center justify-center rounded-xl border border-danger/60 bg-danger/20 text-danger
	`
	return (
		<div className={twMerge(baseClass, className)} ref={ref} {...props}>
			<svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
				<path d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" fillRule='evenodd'></path>
			</svg>
			{children}
		</div>
	)
})


Error.displayName = 'Error'
